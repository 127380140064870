







@media screen and (max-width: 767px) {
    .url_input_scs {
      & > :nth-child(1){
          & > :nth-child(1){
              flex-direction: column !important;
              & > :nth-child(1), & > :nth-child(2){
                  width: 100% !important;
                  & > :nth-child(1){
                      width: 100% !important;
                      margin: 0 !important;
                      margin-top: 2% !important;
                  }
              }
  
          }
      }
    }
  }
  @media screen and (max-width: 1399px) {
    .preview_scs {
      margin-right: 6%;
    }
  }
  @media screen and (max-width: 1199px) {
    .preview_scs {
      margin-right: 15%;
    }
  }
  @media screen and (max-width: 991px) {
    .preview_scs {
      height: 500px;
      margin: 0 auto;
      box-shadow: none !important;
  
      & > :nth-child(1) {
        width: 100% !important;
        height: 500px;
        align-items: center;
        margin: 0 auto;
        box-shadow: none !important;
  
        & > :nth-child(1) {
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        }
      }
    }
    .handel_scs {
      margin-top: 15% !important;
      width: 100% !important;
    }
  }
  
  @media screen and (max-width: 767px) {
    .handel_scs {
      margin-top: 22% !important;
    }
  }
  
  .url_input_scs{
    .spinner-border-sm{
      height: 15px !important;
      width: 15px !important;
    }
  }
  
  /* image upload btn  */
  .handel_scs{
    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }
    
    .btn {
      border: 2px solid gray;
      color: gray;
      background-color: white;
      padding: 8px 20px;
      border-radius: 8px;
      font-size: 20px;
      font-weight: bold;
    }
    
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  